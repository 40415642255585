<template>
  <div class="nursery" v-if="hasPerm('nursery.change_nursery')">
    <page-header
      title="Prestation de Service Unique"
      icon="fa fa-baby-carriage"
      :links="getLinks()"
      print-hour
    ></page-header>
    <div>
      <b-row>
        <b-col>
          <b-form-group label="Lieu" label-for="nurseries">
            <choices-select
              id="nurseries"
              :choices="nurseries"
              v-model="selectedNursery"
              :callback="getNurseryLabel"
            >
            </choices-select>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <date-frame-selector @change="onDateRangeChanged" :start-date="startDate" :end-date="endDate">
          </date-frame-selector>
        </b-col>
        <b-col>
          <b-form-group label-for="includePlanned" label="Options">
            <b-form-checkbox id="includePlanned" v-model="includePlanned">
              Inclure les heures prévues
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div ref="printMe" style="margin-top: 10px;" v-if="isValid">
        <div style="text-align: center">
          <b>
            Prestation de Service Unique - Etat de présence
            <div>0 - 4 ans</div>
            <div v-if="startDate && endDate">
              Du {{ startDate|dateToString }} au {{ endDate|dateToString }}
            </div>
            <div v-if="includePlanned" style="background: #ccc; padding: 5px;">
              <i class="fa fa-warning"></i> INCLUS LES HEURES PRÉVUES
            </div>
          </b>
        </div>
        <b-row>
          <b-col cols="4">
            <div :style="headerBlockStyle">
              <table v-if="!isLoading(loadingName)">
                <tr>
                  <td>
                    <b>Capacité d'accueil globale:</b>
                  </td>
                  <td>
                    <counter-label :counter="maxCapacity" label="place"></counter-label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nb d'heures d'ouverture:</b>
                  </td>
                  <td>
                    <counter-label :counter="openedHours" label="heure"></counter-label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nb de jours d'ouverture:</b>
                  </td>
                  <td>
                    <counter-label :counter="openedDays" label="jour"></counter-label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Capacité d'accueil période:</b>
                  </td>
                  <td>
                    <counter-label :counter="capacity" label="heure"></counter-label>
                  </td>
                </tr>
              </table>
            </div>
          </b-col>
          <b-col cols="4">
            <div :style="headerBlockStyle">
              <div>
                <b>{{ selectedNursery.getLabel() }}</b>
              </div>
              <div>
                {{ selectedNursery.address1 }}<br />
                {{ selectedNursery.address2 }}
              </div>
            </div>
          </b-col>
          <b-col cols="4">
            <div :style="headerBlockStyle">
              <div v-if="manager">
                <b>Personne à contacter:</b>
                {{ manager.lastAndFirstName() }}
              </div>
              <div v-if="manager">
                <b>Téléphone:</b>
                {{ manager.cellPhone }}
              </div>
              <div>
                <b>N° de la structure:</b>
                {{ selectedNursery.reportNumber }}
              </div>
            </div>
          </b-col>
        </b-row>
        <table class="table table-striped small table-bordered">
          <tr>
            <td class="td-blank"></td>
            <th v-for="regime of socialRegimes" :key="regime.id" colspan="2" class="report-col2">
              {{ regime.name }}
            </th>
            <th class="report-col text-center">Total présences</th>
            <th class="report-col text-center">Montant participations</th>
          </tr>
          <tbody v-for="line of lines" :key="line.id">
            <tr>
              <th>{{ line.label }}</th>
              <th v-for="col of socialRegimeColumns" :key="col.id" class="report-col text-center">
                {{ col.name }}
              </th>
              <th class="report-col text-center">Durée</th>
              <th class="report-col text-center">Coût</th>
            </tr>
            <tr>
              <td>
                Heure de présences facturées
              </td>
              <td v-for="col of getPaidValues(line)" :key="col.id" class="number report-col">
                {{ col.value }}
              </td>
            </tr>
            <tr>
              <td>
                Heure de présences réalisées
                <span v-if="includePlanned">et prévues</span>
              </td>
              <td v-for="col of getRealValues(line)" :key="col.id" class="number report-col">
                {{ col.value }}
              </td>
            </tr>
          </tbody>
          <tr>
            <th colspan="2">Nombre d'enfants inscrits sur la période</th>
            <td colspan="4"></td>
            <th class="text-center">Occup. facturée</th>
            <th class="text-center">
              Occup. réalisée
              <span v-if="includePlanned">+ prévue</span>
            </th>
            <th class="text-center">Taux de facturation</th>
          </tr>
          <tr>
            <th colspan="2"><counter-label :counter="childrenCount" label="enfant"></counter-label></th>
            <td colspan="4"></td>
            <th class="number">{{ paidFreqRate }}</th>
            <th class="number">{{ realFreqRate }}</th>
            <th class="number">{{ paidRate }}</th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector.vue'
import ChoicesSelect from '@/components/Controls/ChoicesSelect.vue'
import { currency, dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { makeSocialRegime } from '@/types/families'
import { makeIndividual } from '@/types/people'
import { makeNursery } from '@/types/nursery'
import { getMonthRange } from '@/utils/dates'
import { BackendApi, openDocument } from '@/utils/http'
import { sum } from '@/utils/math'

export default {
  name: 'nursery-report',
  mixins: [BackendMixin],
  components: {
    CounterLabel,
    ChoicesSelect,
    DateFrameSelector,
    LoadingGif,
    PageHeader,
  },
  props: {
  },
  data() {
    return {
      loadingName: 'nursery-report',
      inscriptions: [],
      startDate: null,
      endDate: null,
      showDetail: false,
      nurseries: [],
      selectedNursery: null,
      data: null,
      maxCapacity: 0,
      openedDays: 0,
      openedHours: 0,
      capacity: 0,
      childrenCount: 0,
      socialRegimes: [],
      lines: [],
      manager: null,
      includePlanned: false,
    }
  },
  watch: {
    startDate: function() {
      if (this.startDate && !this.endDate) {
        this.endDate = dateToString(getMonthRange(this.startDate)[1], 'YYYY-MM-DD')
      }
    },
  },
  computed: {
    isValid() {
      return !!(this.selectedNursery && this.startDate && this.endDate)
    },
    socialRegimeColumns() {
      const columns = []
      let counter = 1
      for (const reg of this.socialRegimes) {
        columns.push({ id: counter, name: 'Durée', })
        counter += 1
        columns.push({ id: counter, name: 'Coût', })
        counter += 1
      }
      return columns
    },
    totalRealDuration() {
      if (this.lines.length) {
        // La dernière ligne contient le total
        const lastLine = this.lines[this.lines.length - 1]
        let theSum = sum(lastLine.values.map(elt => +elt['total_report_duration']))
        if (this.includePlanned) {
          theSum += sum(lastLine.values.map(elt => +elt['total_planned_duration']))
        }
        return theSum
      }
      return 0
    },
    totalPaidDuration() {
      if (this.lines.length) {
        // La dernière ligne contient le total
        const lastLine = this.lines[this.lines.length - 1]
        return sum(lastLine.values.map(elt => +elt['total_paid_duration']))
      }
      return 0
    },
    realFreqRate() {
      if (this.capacity) {
        const ratio = Math.round(10000 * this.totalRealDuration / this.capacity) / 100
        return '' + ratio + '%'
      }
      return 0
    },
    paidFreqRate() {
      if (this.capacity) {
        const ratio = Math.round(10000 * this.totalPaidDuration / this.capacity) / 100
        return '' + ratio + '%'
      }
      return ''
    },
    paidRate() {
      const totalRealDuration = this.totalRealDuration
      const totalPaidDuration = this.totalPaidDuration
      if (totalRealDuration && totalPaidDuration) {
        const ratio = Math.round(10000 * totalPaidDuration / totalRealDuration) / 100
        return '' + ratio + '%'
      }
      return ''
    },
    headerBlockStyle() {
      return {
        border: 'solid 1px #000',
        padding: '10px',
        margin: '10px 0',
        'border-radius': '2px',
      }
    },
  },
  mounted() {
    this.loadNurseries()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    getNurseryLabel(elt) {
      return elt.getLabel()
    },
    onDateRangeChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
      this.loadReport()
    },
    async loadNurseries() {
      this.startLoading(this.loadingName)
      let url = '/nursery/api/nurseries/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.nurseries = resp.data.map(elt => makeNursery(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadReport() {
      if (this.isValid) {
        this.startLoading(this.loadingName)
        let url = '/nursery/api/report/' + this.selectedNursery.id + '/'
        url += this.startDate + '/' + this.endDate + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.data = resp.data
          this.manager = resp.data.manager ? makeIndividual(resp.data.manager) : null
          this.maxCapacity = resp.data['max_capacity']
          this.openedDays = resp.data['opened_days']
          this.openedHours = resp.data['opened_hours']
          this.capacity = resp.data['capacity']
          this.childrenCount = resp.data['children_count']
          this.socialRegimes = resp.data['social_regimes'].map(makeSocialRegime)
          this.lines = resp.data.lines
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    getPaidValues(line) {
      const columns = []
      let sumDuration = 0
      let sumAmount = 0
      let id = 0
      for (const regime of this.socialRegimes) {
        id = columns.length + 1
        const matches = line.values.filter(elt => elt['social_regime'] === regime.id)
        if (matches.length === 1) {
          const value = matches[0]['total_paid_duration'] || 0
          sumDuration += value
          columns.push({ id: id, value: value, })
          const price = matches[0]['total_price'] || 0
          sumAmount += price
          columns.push({ id: id + 1, value: currency(price), })
        } else {
          columns.push({ id: id, value: 0, })
          columns.push({ id: id + 1, value: currency(0), })
        }
      }
      id = columns.length + 1
      columns.push({ id: id, value: sumDuration, })
      columns.push({ id: id + 1, value: currency(sumAmount), })
      return columns
    },
    getRealValues(line) {
      const columns = []
      let sumDuration = 0
      let id = 0
      for (const regime of this.socialRegimes) {
        id = columns.length + 1
        const matches = line.values.filter(elt => elt['social_regime'] === regime.id)
        if (matches.length === 1) {
          let value = matches[0]['total_report_duration'] || 0
          if (this.includePlanned) {
            value += matches[0]['total_planned_duration'] || 0
          }
          sumDuration += value
          columns.push({ id: id, value: value, })
        } else {
          columns.push({ id: id, value: 0, })
        }
        columns.push({ id: id + 1, value: '', })
      }
      id = columns.length + 1
      columns.push({ id: id, value: sumDuration, })
      columns.push({ id: id + 1, value: '', })
      return columns
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/?landscape=1'
      const docSlug = 'contrats-creche'
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'contrats-creche'
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style lang="less" scoped>
.header-block {
  border: solid 1px #000;
  padding: 10px;
  margin: 10px 0;
  border-radius: 2px;
}
.report-col {
  width: 150px;
}
.report-col2 {
  width: 300px;
  text-align: center;
}
td.td-blank {
  background: #fff !important;
}
th {
  background: #ccc !important;
}
th.number, td.number {
  text-align: right;
}
</style>
