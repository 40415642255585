<template>
  <b-form-select v-model="selectedChoice" :id="id" :disabled="choices.length <= 1">
    <b-form-select-option
      :value="choice"
      v-for="choice in choices"
      :key="choice.id"
      >
      {{ getLabel(choice) }}
    </b-form-select-option>
  </b-form-select>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ChoicesSelect',
  prop: ['choice'],
  props: {
    id: String,
    choices: Array,
    choice: Object,
    callback: {
      type: Function,
      default: null,
    },
    notAuto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedChoice: null,
    }
  },
  computed: {
  },
  mounted() {
    this.init()
  },
  watch: {
    choices: function() {
      this.init()
    },
    selectedChoice: function() {
      this.$emit('change', this.selectedChoice)
      this.$emit('input', this.selectedChoice)
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      if (!this.notAuto) {
        if (this.choices.length > 0) {
          this.selectedChoice = this.choices[0]
        }
      }
    },
    getLabel(elt) {
      if (this.callback) {
        return this.callback(elt)
      } else {
        return elt.name
      }
    },
  },
}
</script>

<style scoped>
</style>
